import React, { ReactElement, ReactNode, useState } from 'react';
import { Link } from 'gatsby';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

interface LayoutProps {
  children: ReactNode;
  title?: string;
}

const useStyles = makeStyles(() => ({
  drawer: {
    width: 200,
  },
}));

const Layout = ({ children, title }: LayoutProps): ReactElement => {
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const header = (
    <div className="flex justify-between items-center mb-8">
      <h3 className="font-bold text-xl md:text-2xl lg:text-3xl my-0">
        <Link className="shadow-none text-black" to="/">
          brettfisher.dev
        </Link>
      </h3>
      {isSmallScreen ? (
        <IconButton onClick={() => setDrawerOpen(true)}>
          <MenuIcon />
        </IconButton>
      ) : (
        <div>
          <Link className="shadow-none text-black mr-3" to="/contact">
            contact
          </Link>
          <Link className="shadow-none text-black" to="/projects">
            projects
          </Link>
        </div>
      )}
    </div>
  );

  return (
    <div className="mx-auto max-w-2xl py-10 px-6">
      <header>{header}</header>
      {title && <h1 className="mb-6">{title}</h1>}
      <main>{children}</main>
      <footer>©{new Date().getFullYear()} Brett Fisher</footer>
      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        classes={{ paper: classes.drawer }}
      >
        <List>
          <Link to="/contact" className="text-black">
            <ListItem button>
              <ListItemText primary="Contact" />
            </ListItem>
          </Link>
          <Link to="/projects" className="text-black">
            <ListItem button>
              <ListItemText primary="Projects" />
            </ListItem>
          </Link>
        </List>
      </SwipeableDrawer>
    </div>
  );
};

export default Layout;
